import { call, put, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import MailTemplatesActions from '../Redux/MailTemplatesRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getMailTemplates(api, authApi, { page, page_size, sort_field, sort_order, search, filter }) {
  try {
    const response = yield call(api.getMailTemplates, page, page_size, sort_field, sort_order, search, filter);
    if (response && response.ok) {
      yield put(MailTemplatesActions.mailTemplatesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MailTemplatesActions.mailTemplateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getMailTemplates(api, authApi, { page, page_size, sort_field, sort_order, search, filter });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MailTemplatesActions.mailTemplateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getMailTemplate(api, authApi, { templateId }) {
  try {
    const response = yield call(api.getMailTemplate, templateId);
    if (response && response.ok) {
      yield put(MailTemplatesActions.mailTemplateSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MailTemplatesActions.mailTemplateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getMailTemplate(api, authApi, { templateId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(MailTemplatesActions.mailTemplateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateMailTemplate(api, authApi, { templateId, data }) {
  try {
    const response = yield call(api.updateMailTemplate, templateId, data);
    if (response && response.ok) {
      yield put(MailTemplatesActions.mailTemplatesRequest())
      yield delay(500)
      yield put(MailTemplatesActions.mailTemplateSaveSuccess(response.data))
      yield put(MailTemplatesActions.mailTemplateSaveReset())

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else if (response.status === 500) {
          yield put(ErrorsActions.errorSave("Server response error"))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(MailTemplatesActions.mailTemplateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * updateMailTemplate(api, authApi, { templateId, data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(MailTemplatesActions.mailTemplateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
