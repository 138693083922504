import React from 'react';
import { Link } from 'react-router-dom'
import cls from 'classname'
import { Typography } from 'antd'

import { ReactComponent as IconForCompanies } from '../../../Images/landing_new/for_companies.svg';
import { ReactComponent as IconForAdvisers } from '../../../Images/landing_new/for_advisers.svg';
import { ReactComponent as IconForInvestors } from '../../../Images/landing_new/for_investors.svg';
import { ReactComponent as IconForIndustry } from '../../../Images/landing_new/for_industry.svg';
import { ReactComponent as Arrow} from '../../../Images/landing_new/arrow.svg';
import { ReactComponent as Linked} from '../../../Images/landing_new/linked.svg';
import Images from '../../../Images';

const {Title} = Typography

export const ServicesBlock = () => {

  const data = [
    {
      title: <div className='text'>For <br/>Companies</div>,
      subTitle: 'IDN connects companies with investors to accelerate growth, sell shares, and divest assets.',
      icon: <IconForCompanies className="icon" />,
    },
    {
      title: <div className='text'>For M&A <br/>Advisors</div>,
      subTitle: 'IDN selectively introduces investors to M&A advisors, enhancing sell-side and securing buy-side mandates.',
      icon: <IconForAdvisers className="icon" />,
    },
    {
      title: <div className='text'>For PE <br/>Investors</div>,
      subTitle: 'IDN connects investors with high-quality, infrastructure-like opportunities across Europe.',
      icon: <IconForInvestors className="icon" />,
    },
    {
      title: <div className='text'>For Industry<br/> Executives</div>,
      subTitle: 'IDN secures PE buy-side mandates to senior executives with actionable investment ideas.',
      icon: <IconForIndustry className="icon" />,
    },
  ]

  return (
    <div className='service-block'>
      {data.map((el, i) => (
        <div key={i} className='service-block-item'>
          <div className='arrow-wrapper'>
            <div className='learn-more'>Learn more</div>
            <Arrow className="arrow" />
          </div>
          {el.icon}
          {el.title}
          <div className='sub-text'>{el.subTitle}</div>
        </div>
      ))}
    </div>
  )
}


export const UniqueBlock = ({index, setActiveSlide, maxSlidesIndex}) => {

  const data = [
    {
      title: 'Our Network',
      text_1: 'IDN has built a vast network of companies, advisors, senior executives, and investors in the infrastructure sector',
      text_2: 'Through our proprietary platform, we serve as a centralised, specialised hub, seamlessly connecting all relevant parties seeking and providing capital.',
      image: Images.slide_1,
    },
    {
      title: 'Business model',
      text_1: 'IDN operates predominantly on the buy-side, where we are engaged and compensated by private equity investors.',
      text_2: 'This approach incentivizes companies and advisors to collaborate with us, offering streamlined access to leading investors and enabling highly targeted, strategic introductions.',
      image: Images.slide_2,
    },
    {
      title: 'Proprietary Technology',
      text_1: 'IDN`s proprietary online platform streamlines interactions between advisors, senior executives, and investors, creating a secured environment for engaging in M&A activities.',
      text_2: 'Our technology enables targeted and efficient matchmaking, enhancing the visibility and accessibility of key investment opportunities to qualified private equity investors.',
      image: Images.slide_3,
    },
    {
      title: 'Legal Framework',
      text_1: 'Our robust legal framework supports advisors and senior executives in engaging with investment firms on buy-side mandates.',
      text_2: 'This streamlined approach simplifies onboarding, minimizes transactional risks, and ensures a compliant, seamless pathway for all counterparties, facilitating effective and transparent engagements.',
      image: Images.slide_4,
    },
  ]

  return (
    <div className='main-unique-block animated-block'>
      <div className='left-square-bg'></div>
      <div className='slider-img-wrapper'>
        <img src={data[index]?.image} alt='' />
      </div>
      <div className='content-wrapper'>
        <div className='arrow-slider-unique-block-wrapper'>
          <ButtonWithArrow
            text={null}
            className={'arrow-slider-unique-block'}
            onClick={()=> setActiveSlide((prev) => (prev > 0 ? prev - 1 : maxSlidesIndex))}
          />
          <ButtonWithArrow
            text={null}
            className={'arrow-slider-unique-block'}
            onClick={()=> setActiveSlide((prev) => (prev < maxSlidesIndex ? prev + 1 : 0))}
          />
        </div>
        <div className='block-title white unique'>Our Unique Approach</div>

        <div className='content-title white'>
          {data[index]?.title}
        </div>
        <div className='content-text white'>
          {data[index]?.text_1}
          <br />
          <br />
          {data[index]?.text_2}
        </div>
        <div className='custom-dots-wrapper'>
         {data.map((el, i)=> (
            <div
             key={i}
             className='click-wrapper'
            //  className={cls('custom-dot', {'active': i === index})}
             onClick={()=> setActiveSlide(i)}
            >
              <div className={cls('custom-dot', {'active': i === index})}></div>
            </div>
         ))}
         <div className='counter'>{`0${index + 1}/0${data.length}`}</div>
        </div>
      </div>
    </div>
  )
}

export const GetInTouchBlock = ({text_1, text_2}) => {

  return (
    <div className='main-get-in-touch-block animated-block' id='intouch-block'>
      <div className='third-square'></div>
      <div className='block-title get-in-touch'>Get in touch</div>
      <Title className='title font-60 white' style={{textAlign: 'center'}}>
        {text_1}
      </Title>
      <div className='contact-us-wrapper'>
        <div className='contact-us'>Contact us</div>
        <Arrow className="arrow" />
      </div>
    </div>
  )
}

export const ButtonWithArrow = ({text, className, style, onClick}) => {

  return (
    <div className={cls('button-with-arrow-wrapper', {[className]: className})} style={{cursor: 'pointer', ...style}} onClick={onClick}>
      <div>{text}</div>
      <Arrow className="arrow" />
    </div>
  )
}

export const Footer = () => {

  return (
    <div className='footer-wrapper'>
      <div className='footer-block rights'>
        <div className='footer-text rights'>2025 IDN, All rights reserved</div>
      </div>
      <div className='footer-block links'>
        <div className='footer-text link'>
          <Link to="/privacy-policy">Privacy policy</Link>
        </div>
        <div className='footer-text link'>
          <Link to="/cookies-policy">Cookie policy</Link>
        </div>
        <div className='footer-text link'>
          <Link to="/terms-of-use">Terms of Use</Link>
        </div>
      </div>
      <div className='footer-block get-in-touch'>
        <div className='footer-text fw-300'>Get in touch with us</div>
        <div className='footer-text link'>
          <a href="mailto:info@idn.ltd">
            info@idn.ltd
          </a>
        </div>
        <div className='footer-text'>
          <a
            href="https://uk.linkedin.com/company/idn-the-infrastructure-deals-network"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linked className="icon-linked" style={{cursor: 'pointer'}}/>
          </a>
        </div>
      </div>
    </div>
  )
}