import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classname'
import { Typography } from 'antd'

import Images from '../../Images'
import MainHeaderNew from './MainHeaderNew.js'

import './MainPage.less'
import './MainPageNew.less'
import MainPageCarousel from './components/MainPageCarousel.js'
import { ButtonWithArrow, Footer, GetInTouchBlock, ServicesBlock, UniqueBlock } from './components/helpers.js'
import { ReactComponent as Arrow} from '../../Images/landing_new/arrow.svg';
// import ScrollLockedSlider from './ScrollLockedSlider.js'

const {Title, Text} = Typography

const dataSectors = [
  {
    title: <span>Energy & <br /> Environment</span>,
    image: Images.sector_1,
  },
  {
    title: <span>Transport & <br /> Logistics</span>,
    image: Images.sector_2,
  },
  {
    title: <span>Telecom & <br/> Data Infrastructure</span>,
    image: Images.sector_3,
  },
  {
    title: <span>Social & <br/> Healthcare</span>,
    image: Images.sector_4,
  },
]

//for lines on background
const scailing = (scrollableElement) => {
  const scrollTop = scrollableElement.scrollTop;
  const scaleFactor = Math.min(1.2, 1 + scrollTop / 1000);
  document.documentElement.style.setProperty('--scale-factor-1', scaleFactor);
};

//for lines on get in touch block
const scailing2 = (scrollableElement) => {
  const block = document.getElementById('intouch-block');
  const rect = block.getBoundingClientRect();
  // console.log(rect.top )
  const isVisible = rect.top >= -80 && rect.top <= 1600;
  if (isVisible) {
    const scrollTop = scrollableElement.scrollTop;
    const scaleFactor = Math.min(1.2, 1 + scrollTop / 1000);
    document.documentElement.style.setProperty('--scale-factor-2', scaleFactor);
  } else {
    document.documentElement.style.setProperty('--scale-factor-2', 1);
  }
};

function MainPage(props) {
	const [animate, setAnimate] = useState(false);
	const [activeSlide, setActiveSlide] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  // const [showNextSlide, setShowNextSlide] = useState(false);
  const maxSlidesIndex = 3;

  const helpRef = useRef({
    firstViewed: false,
    autoplay: true,
  })

  useEffect(() => {
    const timerTopImageAnimation = setTimeout(() => {
      setAnimate(true);
    }, 100);

    const element = document.getElementById('scrollableElement');
    // const element = document.getElementById('scr');

    let timer;
    let canStopScroll = true;

    const checkVisibility = () => {
      const targetElement = document.querySelector('.content-wrapper');
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.top <= 260;
        // console.log(isVisible, rect.top)
        // const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isVisible && canStopScroll) {
          // element.style.overflow = 'hidden';
          // canStopScroll = false;
          // timer = setTimeout(() => {
          //   setActiveSlide((prev) => (prev < maxSlidesIndex ? prev + 1 : prev));
          // }, 2500);
        }
      }

      scailing(element);
      scailing2(element);
    };

    element.addEventListener('scroll', checkVisibility);
    // element.addEventListener('wheel', handleWheel);

    const animateBlock = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const block = entry.target;

            // if (entry.isIntersecting) {
            //   block.classList.add("visible");
            // } else {
            //   block.classList.remove("visible");
            // }
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.25, // works when 25% block is visible
        }
      );

      const animatedBlocks = document.querySelectorAll(".animated-block");
      animatedBlocks.forEach((block) => observer.observe(block));
    }
    //add animation for block of content
    document.addEventListener("DOMContentLoaded", animateBlock)

    return () => {
      element.removeEventListener('scroll', checkVisibility);
      document.removeEventListener('DOMContentLoaded', animateBlock);
      // element.removeEventListener('wheel', handleWheel);
      if (timer) clearTimeout(timer);
      if (timerTopImageAnimation) clearTimeout(timerTopImageAnimation);
    };
  }, []);

  // useEffect(() => {
  //   if (showNextSlide) {
  //     // if (helpRef.current.firstViewed) {
  //     //   setActiveSlide((prev) => (prev < maxSlidesIndex ? prev + 1 : prev));
  //     // }

  //     // if (!helpRef.current.firstViewed) {
  //     //   helpRef.current.firstViewed = true;
  //     //   const timer = setTimeout(() => {
  //     //     setShowNextSlide(false)
  //     //   }, 2000);
  //     //   return ()=> clearTimeout(timer);
  //     // }
  //   }
  // }, [showNextSlide]);

  useEffect(() => {
    let timer;

    // if (activeSlide > 0 && activeSlide !== 3) {
      // timer = setTimeout(() => {
        //   setShowNextSlide(false);
        // }, 2000);
    if (activeSlide > 0 && activeSlide !== 3 && helpRef.current.autoplay) {
      timer = setTimeout(() => {
        setActiveSlide((prev) => (prev < maxSlidesIndex ? prev + 1 : prev));
      }, 3000);
    } else if (activeSlide === 3) {
      const element = document.getElementById('scrollableElement');
      timer = setTimeout(() => {
        element.style.overflow = 'auto';
      }, 3000);
      helpRef.current.autoplay = false;
    }

    return () => clearTimeout(timer);
  }, [activeSlide]);

	return (
		<div className="main-page-new" id='scrollableElement'>
			<MainHeaderNew />
      <div className='main-content-new'>
        <div className={cls("main-top-block", { animate })}>
          <video
            className="video-bg"
            src="https://api.idn.ltd/api/media/WhatsApp_Video_2024-12-10_at_18.46.55_848a44d7.mp4"
            autoPlay
            muted
            loop
            playsInline
          >
          </video>
          <div className='main-top-title-block'>
            <Title className='title font-60 white'>
              The Infrastructure <br/> Deals Network
            </Title>
            <Title className='title sub-title font-28 white'>
              Your Trusted Partner for accessing private equity capital in Europe
            </Title>
          </div>
          <div className="mask"></div>
          <div className="gradient"></div>
        </div>

        <div className='main-about-us-block animated-block'>
          <div className='block-title'>About us</div>
          <Title className='title'>
            IDN the leading M&A platform connecting the infrastructure ecosystem across Europe.
          </Title>
          <Text className='text'>
            We've Successfully Facilitated Deals with Firms and Funds Associated With:
          </Text>
          <MainPageCarousel />
        </div>

        <div className='main-our-services-block animated-block'>
          <div className='block-title'>Our services</div>
          <Title className='title'>
            We partner with companies, M&A advisors, and senior industry leaders, aligning private equity capital with their strategic ambitions and needs.
          </Title>
          <Text className='text'>
            We've Successfully Facilitated Deals with Firms and Funds Associated With:
          </Text>
          <ServicesBlock />
        </div>

        {/* <ScrollLockedSlider /> */}

        <UniqueBlock index={activeSlide} setActiveSlide={setActiveSlide} maxSlidesIndex={maxSlidesIndex} />

        <div className='main-sectors-block animated-block'>
          <div className='content-wrapper'>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Title className='title'>Sectors</Title>
              <Link to="/sectors">
                <ButtonWithArrow
                  text={'Learn more'}
                  style={{marginTop: 10}}
                  className={'sectors'}
                />
              </Link>
            </div>
              <div className='sectors-img-wrapper' >
                {dataSectors.map((el, i) => (
                  <div className='sector-item'>
                    <img src={el.image} alt='' width={'100%'} />
                    <div className='img-mask'></div>
                    <ButtonWithArrow
                      text={null}
                      className={'arrow-sector'}
                    />
                    <div className='sector-title white'>{el.title}</div>
                    <div className='arrow-wrapper-learn-more'>
                      <div className='learn-more white'>Learn more</div>
                      <Arrow className="arrow" />
                    </div>
                  </div>
                ))}
              </div>
          </div>
        </div>

        <div className='main-why-block animated-block'>
          <div className='left-part'>
            <div className='block-title why-idn'>Why IDN</div>
            <div className='block-text'>
              IDN stands out as a trusted partner for all participants in Europe's private equity infrastructure ecosystem, fostering &nbsp;
              <span style={{color: '#F8941E'}}>M&A and capital raise activities by connecting</span>&nbsp;
              companies, advisors, and senior executives, to leading private equity investors through our specialised expertise and technology-enabled platform.
            </div>
            {/* <div className='tags-block'>
              <div className='tag'>
                <img src={Images.connection} alt='' />
                <div>Connection</div>
              </div>
              <div className='tag'>
                <img src={Images.win} alt='' />
                <div>Win-win</div>
              </div>
              <div className='tag'>
                <img src={Images.trust} alt='' />
                <div>Trust</div>
              </div>
            </div> */}
          </div>
          <div className='right-part'>
            <img src={Images.why_idn} alt='' />
          </div>
        </div>

        <GetInTouchBlock
          text_1={<span>Partner with IDN to access capital, <br/> secure mandates, and discover new <br/> investment opportunities.</span>}
        />
        <Footer />

      </div>
		</div>
	)
}

export default MainPage;
