import React from 'react';
import {Link} from 'react-router-dom'
import {Dropdown, Menu, Divider} from 'antd'

import { ReactComponent as MenuIcon} from '../../../Images/landing_new/menu.svg';
import './MenuComponent.less'

const menu = (
  <Menu>
    <Link to="/services">
      <Menu.Item>Our Services</Menu.Item>
    </Link>
    <Link to="/investors">
      <Menu.Item>For investors</Menu.Item>
    </Link>
    <Link  to="/transactions">
      <Menu.Item>Transactions</Menu.Item>
    </Link>
    <Link  to="/sectors">
      <Menu.Item>Sectors</Menu.Item>
    </Link>
    {/* <Divider style={{margin: '4px 0'}} /> */}
    <Menu.Item className='align-end'>Get in touch</Menu.Item>
    <Link  to="/login">
      <Menu.Item className='align-end'>Login</Menu.Item>
    </Link>
  </Menu>
);

const MenuComponent = () => {

  return (
    <div className="dropdown-wrap">
      <Dropdown
        overlay={menu}
        overlayClassName={'custom-menu'}
        trigger={['click']}
        placement="bottomLeft"
      >
        <MenuIcon className='header-menu-icon'/>
      </Dropdown>
    </div>
  )
}

export default MenuComponent;